import { mapGetters } from 'vuex';

export default {
  props: {
    parentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingDependents: false,
    };
  },
  watch: {
    parentId: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          this.loadingDependents = true;
          this.$store.commit('dependents/clearDependents');
          await this.$store.dispatch('dependents/fetchDependents', { parentId: newValue, centerId: this.centerId });
          this.loadingDependents = false;
        } else {
          this.$store.commit('dependents/clearDependents');
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      dependents: 'dependents/asArray',
    }),
  },
};
